<template>
  <div
    class="is-flex is-align-items-center is-justify-content-space-between is-hidden-desktop mobile-header"
  >
    <template v-if="!searching">
      <MobileMenu
        :avatarUrl="avatarUrl"
        :userName="userName"
        :logged="logged"
        :userAdmin="userAdmin"
      />
      <a :href="root_path()" class="logo-img is-flex" aria-label="Home erural">
        <LogoIcon alt="erural logo" />
      </a>
      <div class="search-icon mr-2">
        <SearchIcon @click.prevent="searching = true" />
      </div>
    </template>
    <template v-else>
      <div class="search-icon">
        <SearchIcon class="mx-4" />
      </div>
      <div class="input-search-container">
        <input
          v-model="searchString"
          type="search"
          class="input p-0"
          placeholder="O que você procura?"
          @keydown.enter.stop.prevent="query = searchString"
        />
      </div>
      <CloseIcon class="mx-4 close-icon" @click.prevent="searching = false" />
    </template>
  </div>
</template>

<script>
import { ref, toRefs, watch } from "vue";
import { filtersState } from "components/search/filtersState";
import { root_path } from "utils/routes";

import MobileMenu from "./MobileMenu.vue";

import SearchIcon from "icons/cached/search.svg";
import CloseIcon from "icons/cached/close.svg";
import LogoIcon from "images/logos/erural_color.svg";

export default {
  components: {
    SearchIcon,
    CloseIcon,
    MobileMenu,
    LogoIcon,
  },
  props: {
    avatarUrl: {
      type: String,
      default: "",
    },
    userName: String,
    logged: Boolean,
    userAdmin: { type: Boolean, default: false },
  },
  setup() {
    const searching = ref(false);

    const { query } = toRefs(filtersState);
    const searchString = ref(query.value);

    watch(query, (cur) => {
      searchString.value = query.value;
    });

    return {
      searching,
      root_path,
      query,
      searchString,
    };
  },
};
</script>

<style></style>
