<template>
  <div
    class="overlay"
    :class="{ 'is-hidden': !open }"
    @click="() => (open = false)"
  />
  <div class="menu-icon ml-2" @click="() => (open = !open)">
    <MenuIcon />
  </div>
  <div v-if="open" class="side-menu">
    <div class="menu-wrapper">
      <div class="menu-header">
        <div class="close-icon" @click="() => (open = false)">
          <CloseIcon class="is-flex mr-2" />
        </div>
      </div>
      <div class="menu-body">
        <div v-if="logged" class="user-greetings">
          <img v-if="avatarUrl != null" :src="avatarUrl" />
          <AccountIcon v-else class="test-class" />

          <div class="messages">
            <p class="has-text-weight-semibold user-message">
              Olá, {{ userName }}, tudo bem?
            </p>
            <span>Navegue entre as páginas e configurações abaixo.</span>
          </div>
        </div>
        <a v-else class="login-call" :href="new_user_registration_path()">
          <span class="has-text-weight"> Entrar ou criar conta </span>
          <ArrowRightIcon />
        </a>
        <hr />
        <ul class="links-list">
          <li
            v-for="item in items"
            :key="item.title"
            class="has-text-weight-semibold"
          >
            <a :href="item.link">
              <component :is="item.icon" /> {{ item.title }}
            </a>
          </li>
        </ul>
        <hr />
        <ul v-show="logged" class="links-list">
          <li v-if="userAdmin" class="has-text-weight-semibold">
            <a :href="index_pa_path()" rel="”nofollow”">
              <PAIcon /> PAINEL ADMINISTRATIVO
            </a>
          </li>
          <li class="has-text-weight-semibold">
            <a :href="user_profile_url()"> <AccountIcon /> MINHA CONTA </a>
          </li>
          <li class="has-text-weight-semibold">
            <a rel="”nofollow”" @click.prevent="logout">
              <LogoutIcon /> SAIR
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  user_profile_url,
  index_pa_path,
  destroy_user_session_path,
  root_path,
  crawled_auctions_index_path,
  search_ad_path,
  rankings_index_path,
  selections_index_path,
  new_user_registration_path,
  sell_with_us_path,
} from "utils/routes";

// ICONS
import MenuIcon from "icons/cached/menu.svg";
import CloseIcon from "icons/cached/close.svg";
import ArrowRightIcon from "icons/cached/arrow_forward.svg";

import HomeIcon from "icons/cached/home.svg";
import AuctionIcon from "icons/cached/gavel.svg";
import SemenIcon from "icons/cached/semen.svg";
import DnaIcon from "icons/cached/dna_2.svg";
import MeatIcon from "icons/cached/meat.svg";
import TrendingUpIcon from "icons/cached/trending_up.svg";
import BovineIcon from "icons/cached/bovine.svg";
import FeedIcon from "icons/cached/feed.svg";
import AccountIcon from "icons/cached/account_circle.svg";
import LogoutIcon from "icons/cached/logout.svg";
import PAIcon from "icons/cached/view_module.svg";
import SellIcon from "icons/cached/sell.svg";
import TrophyIcon from "icons/cached/trophy.svg";
import AnimalDataIcon from "icons/cached/animal_data.svg";

import api from "services/api";
const items = [
  { title: "Início", icon: "home-icon", link: root_path() },
  {
    title: "Agenda de Eventos",
    icon: "auction-icon",
    link: crawled_auctions_index_path(),
  },
  {
    title: "Genética",
    icon: "dna-icon",
    link: search_ad_path({
      classifications: "Genética",
      genetic: "P.O.,P.A.,P.C.,CEIP",
    }),
  },
  {
    title: "Sêmen Genex",
    icon: "semen-icon",
    link: search_ad_path({ categories: "Sêmen Genex" }),
  },
  {
    title: "Rankings",
    icon: "trophy-icon",
    link: rankings_index_path(),
  },
  {
    title: "Seleções",
    icon: "animal-data-icon",
    link: selections_index_path(),
  },
  {
    title: "Venda conosco",
    icon: "sell-icon",
    link: sell_with_us_path(),
  },
  {
    title: "Blog",
    icon: "feed-icon",
    link: "https://blog.erural.net/",
  },
];

export default {
  components: {
    HomeIcon,
    AuctionIcon,
    SemenIcon,
    MeatIcon,
    DnaIcon,
    TrendingUpIcon,
    BovineIcon,
    FeedIcon,
    AccountIcon,
    MenuIcon,
    CloseIcon,
    LogoutIcon,
    ArrowRightIcon,
    PAIcon,
    SellIcon,
    TrophyIcon,
    AnimalDataIcon,
  },
  props: {
    avatarUrl: {
      type: String,
      default: "",
    },
    userName: String,
    logged: Boolean,
    userAdmin: Boolean,
  },
  setup() {
    const open = ref(false);

    const logout = () => {
      api
        .delete(destroy_user_session_path())
        .finally(() => window.location.reload());
    };

    return {
      open,
      user_profile_url,
      new_user_registration_path,
      index_pa_path,
      items,
      logout,
    };
  },
};
</script>
