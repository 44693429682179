import { reactive, watch, ref, toRaw, watchEffect } from "vue";
import { fillBatches } from "./useBatches";
import api from "services/api";

const initialState = {
  query: "",
  categories: [],
  breeds: [],
  ages: [],
  classifications: [],
  genetic: [],
  states: [],
  zebu_ranking: [],
  zebu_index_min: "",
  zebu_index_max: "",
  ascendancies: [],
  page: 1,
};

const totalPages = ref(1);
const loading = ref(false);

const filtersState = reactive({ ...initialState });

const clearState = () => {
  Object.assign(filtersState, initialState);
};

const updateUrl = () => {
  const searchParams = new URLSearchParams(
    toRaw({ ...filtersState, q: filtersState.query }),
  );
  let deleteKeys = [];
  for (let pair of searchParams.entries()) {
    if (!pair[0] || pair[1].length == 0) {
      deleteKeys.push(pair[0]);
    }
  }
  for (let key in deleteKeys) {
    searchParams.delete(deleteKeys[key]);
  }

  window.history.pushState("", "", "/search?" + searchParams.toString());
};

const queryBatches = () => {
  loading.value = true;
  api
    .get("/search/results", {
      params: {
        q: filtersState.query,
        categories: filtersState.categories,
        breeds: filtersState.breeds,
        ages: filtersState.ages,
        classifications: filtersState.classifications,
        genetic: filtersState.genetic,
        states: filtersState.states,
        zebu_ranking: filtersState.zebu_ranking,
        zebu_index_min: filtersState.zebu_index_min,
        zebu_index_max: filtersState.zebu_index_max,
        ascendancies: filtersState.ascendancies,
        page: filtersState.page,
      },
    })
    .then((data) => {
      totalPages.value = data.data.total_pages;

      fillBatches(data.data.batches, data.data.total_results);

      sendSearchMixpanelTrack(filtersState);
    })
    .finally(() => (loading.value = false));
};

watchEffect(() => {
  if (!window.location.href.includes("search") && filtersState.query != "") {
    window.location.href = `/search?q=${filtersState.query}`;
  }
});

const fillStateFromUrl = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  for (let [key] of Object.entries({ ...filtersState })) {
    let paramValue = urlParams.get(key);

    if (paramValue != null) {
      let valuesArray = paramValue.split(",");

      valuesArray = valuesArray.filter((item) => !!item);
      filtersState[key] = valuesArray;
    }
  }

  filtersState.query = urlParams.get("q") ? urlParams.get("q") : "";

  filtersState.page = urlParams.get("page") ? Number(urlParams.get("page")) : 1;

  watch(filtersState, () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let curPage = urlParams.get("page") ? Number(urlParams.get("page")) : 1;

    if (curPage == filtersState.page) {
      filtersState.page = 1;
    } else {
      window.scrollTo(0, 0);
    }

    updateUrl();
    queryBatches();
  });
};

const sendSearchMixpanelTrack = (filter) => {
  let presentFilters = [];

  if (filter.ages.length > 0) presentFilters.push("Ages");

  if (filter.breeds.length > 0) presentFilters.push("Breeds");

  if (filter.categories.length > 0) presentFilters.push("Categories");

  if (filter.classifications.length > 0) presentFilters.push("Classifications");

  if (filter.genetic.length > 0) presentFilters.push("Genetic");

  if (filter.states.length > 0) presentFilters.push("States");

  if (filter.query != "") presentFilters.push("Query");

  if (filter.zebu_index_min != "") presentFilters.push("iABCZ Minimum");

  if (filter.zebu_index_max != "") presentFilters.push("iABCZ Maximum");

  if (filter.zebu_ranking.length > 0) presentFilters.push("Ranking");

  if (filter.ascendancies.length > 0) presentFilters.push("Ascendancies");

  mixpanel.track("Product Searched", {
    "Search Ages": filter.ages,
    "Search Breeds": filter.breeds,
    "Search Categories": filter.categories,
    "Search Classifications": filter.classifications,
    "Search Genetic": filter.genetic,
    "Search States": filter.states,
    "Search iABCZ Minimun": filter.zebu_index_min,
    "Search iABCZ Maximun": filter.zebu_index_max,
    "Search Ranking": filter.zebu_ranking,
    "Search Ascendancies": filter.ascendancies,
    "Search Query": filter.query,
    "Search Options": presentFilters,
    Page: "Search Page",
  });
};

export { filtersState, clearState, fillStateFromUrl, totalPages, loading };
