<template>
  <div class="search-field">
    <div class="input-container">
      <SearchIcon @click.prevent="query = searchString" />
      <input
        v-model="searchString"
        placeholder="O que você está procurando?"
        @keyup.enter.stop.prevent="query = searchString"
      />
    </div>
  </div>
</template>

<script>
import SearchIcon from "icons/cached/search.svg";

import { filtersState } from "components/search/filtersState";
import { ref, toRefs, watch } from "vue";

export default {
  components: {
    SearchIcon,
  },
  setup() {
    const { query } = toRefs(filtersState);
    const searchString = ref(query.value);

    watch(query, (cur) => {
      searchString.value = query.value;
    });

    return {
      query,
      searchString,
    };
  },
};
</script>
