import { ref } from "vue";

const batches = ref([]);
const totalBatches = ref(0);

const fillBatches = (data, total_batches) => {
  batches.value = data;
  totalBatches.value = total_batches;
};

export { batches, totalBatches, fillBatches };
