<template>
  <div
    v-if="logged"
    class="dropdown user-infos-container"
    :class="{ 'is-active': active }"
  >
    <div class="dropdown-trigger" @click="() => (active = !active)">
      <div class="dropdown-placeholder">
        <figure v-if="avatarUrl" class="image user-picture">
          <img class="is-rounded" :src="avatarUrl" />
        </figure>
        <AccountIcon v-else class="account-icon" />
        <span class="ml-2 mr-2 has-text-weight-regular">
          Olá, {{ userName }}!
        </span>
        <ExpandIcon />
      </div>
    </div>
    <div
      id="dropdown-menu"
      class="dropdown-menu"
      role="menu"
      @mouseleave="() => (active = !active)"
    >
      <div class="dropdown-content has-shadow-4">
        <a
          v-if="userAdmin"
          rel="nofollow"
          :href="index_pa_path()"
          class="dropdown-item"
        >
          Painel Administrativo
        </a>
        <a :href="user_profile_url()" class="dropdown-item"> Meus dados </a>
        <hr class="dropdown-divider mx-2" />
        <a :href="contact_url()" class="dropdown-item"> Preciso de ajuda </a>
        <hr class="dropdown-divider mx-2" />
        <a
          rel="nofollow"
          class="dropdown-item"
          @click.prevent="logout"
        >
          Sair
        </a>
      </div>
    </div>
  </div>
  <div v-else class="logged-out">
    <a :href="new_user_registration_url()" class="button is-outlined is-small">
      Entrar
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
import api from "services/api";
import AccountIcon from "icons/cached/account_circle.svg";
import ExpandIcon from "icons/cached/expand_more.svg";

import {
  contact_url,
  index_pa_path,
  user_profile_url,
  new_user_registration_url,
  destroy_user_session_url,
} from "utils/routes";

export default {
  components: {
    AccountIcon,
    ExpandIcon,
  },
  props: {
    userName: String,
    avatarUrl: String,
    logged: Boolean,
    userAdmin: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const active = ref(false);

    const logout = () => {
      api
        .delete(destroy_user_session_url())
        .finally(() => window.location.reload());
    };

    return {
      active,
      logout,
      contact_url,
      index_pa_path,
      user_profile_url,
      new_user_registration_url,
      destroy_user_session_url,
    };
  },
};
</script>
